import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from '@reach/router';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';


import {usePageContent} from 'src/functionality/content-provider';
import Page from 'src/components/functional/page';
import ContentBlock from 'src/components/functional/content-block';
import {H3} from 'src/components/functional/headings';

import Spacer from 'src/components/graphical/spacer';
import {theme} from 'src/styles/theme';

import PlainHeroSection from 'src/components/functional/hero-section-plain';

import MoreAboutUs from 'src/components/functional/more-about-us-section';
import Diagram from 'images/about/health-and-safety/ohs-diagram.svg';
import placeholderImage from 'images/placeholder.jpg';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  & blockquote {
    padding: 0.5rem 2vw;
    background-color: ${theme.primary};
    border: 0;
    color: ${theme.light};
    border-top: 0.3em solid ${theme.darkPrimary};
  }
  & blockquote a {
    color: black;
  }
`;

const HealthAndSafetyPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  return (
    <Page white="true">
      <PlainHeroSection
        backgroundColor={theme.mediumGray}
        breadcrumbs="true"
        content={content}
        tag='HS01'
        bgImage={placeholderImage}
      />
      <Wrapper>
        <span id="COVID"/>
        <Spacer height={4}/>
        <section>
          <h2 className="display-3 text-primary">
            Coronavirus (COVID-19)
          </h2>
          <Spacer height={2} />
          <blockquote>
            <ContentBlock // Services & Programs
              header={(props) => <h3
                noUnderline
                className="display-5"
                {...props}/>}
              content={content}
              tag='HS04'
            />
          </blockquote>
          <ContentBlock // Services & Programs
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='HS05'
          />
          <ContentBlock // Offices
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='HS06'
          />
          <ContentBlock // Appointments
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='HS07'
          />
          <ContentBlock // Looking after your health
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='HS08'
          />
        </section>
        <Spacer height={4}/>
        <hr />
        <Spacer height={4}/>
        <section>
          <h2 className="display-3 text-primary">
            Occupational Health and Safety
          </h2>
          <Spacer height={4} />
          <ContentBlock
            header={() => <></>}
            content={content}
            tag='HS02'
          />
          <img src={Diagram} alt="arbias Health and Safety Diagram" />
          <ContentBlock
            header={() => <></>}
            content={content}
            tag='HS03'
          />
        </section>
      </Wrapper>
      <MoreAboutUs />
    </Page>
  );
};

HealthAndSafetyPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default HealthAndSafetyPage;


